.notfound__main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  flex-direction: column;
  margin-top: 30px;
}

  @media (max-width: 768px) {
    .notfound__main {
        height: auto; /* Change the height to 'auto' to allow content to push it down */
        margin-top: 70px; /* Add margin-top to avoid overlapping with the header */
    }
}

  .gradient__text {
    text-align: center;
    font-size: 24px;
    font-family: var(--font-family);
  }

  .notfound__main button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #800080;
    border: 2px solid #800080;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 5px; /* Curved edges */
    margin-top: 20px; 
    margin-bottom: 20px;
   
}

