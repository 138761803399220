.Blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.header-image {
    width: 70%;      
    height: 60vh;  
    overflow: hidden; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.header-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;        
    object-position: center;    
}

.title_blog {
    font-size: 36px;
    text-align: center;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 28px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #81AFDD;
    font-weight: 600;
}

.difficulties-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 10px;
  }
  
.difficulties-list li {
    margin-bottom: 10px;
}

.blog_content {
    font-size: 18px;
    line-height: 1.6;
    color: white;
    text-align: justify;
    width: 70%;
    margin-bottom: 40px;
}

.blog_footnote {
    font-size: 14px;
    color: #888;
    width: 70%;
    text-align: left;
}

.blog_footnote p {
    margin-top: 20px;
}

.blog_footnote a {
    color: #0066cc;
    text-decoration: none;
}

.blog_footnote a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .gradient__text h1 {
        font-size: 2rem;
    }

    .Blog {
        padding: 15px;
    }

    .header-image {
        width: 100%;
        height: 40vh;
    }

    .blog_content {
        width: 100%;
    }

    .blog_footnote {
        width: 100%;
    }
}